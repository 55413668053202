<template>
  <div class="content">
    <top-title>
      <span slot="left">账号信息</span>
      <span slot="right">账号信息</span>
    </top-title>
    <div class="part1">
      <div class="data border">
        <p class="tit">基本信息</p>
        <el-row class="el-row">
          <el-col :span="24" :md="12">
            <el-col :span="24" :md="4" class="lable">
              <span>头像</span>
            </el-col>
            <el-col :span="24" :md="20">
             <div class="demo-image__preview">
              <el-image 
                class="avatar" 
                :src="basic.avatar" 
                :preview-src-list="[basic.avatar]">
              </el-image>
            </div>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="el-row">
          <el-col :span="24" :md="12">
            <el-col :span="24" :md="4" class="lable">
              <span>昵称</span>
            </el-col>
            <el-col :span="24" :md="20">
              <p>{{basic.nickName}}</p>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="el-row">
          <el-col :span="24" :md="12">
            <el-col :span="24" :md="4" class="lable">
              <span>实名</span>
            </el-col>
            <el-col :span="24" :md="20">
              <p>
                {{basic.name}}
                <strong >
                  <em :class="!basic.realType ? 'error' : 'success'"></em>
                  <router-link
                    style="
                    text-decoration: none;
                    margin-left: 10px;
                    color: #409eff;
                  "
                    to="/user/real-auth"
                  >查看或修改认证</router-link>
                </strong>
              </p>
            </el-col>
          </el-col>
        </el-row>
        <p class="tit" style="margin-top:20px">联系方式</p>
        <el-row class="el-row">
          <el-col :span="24" :md="12">
            <el-row class="el-row">
              <el-col :span="24" :md="4" class="lable">
                <span>邮箱</span>
              </el-col>
              <el-col :span="24" :md="20">
                <p>
                  {{basic.mail}}
                  <router-link
                    style="color: #888; margin-left: 10px"
                    class="fa-pencil fa"
                    to="/user/set"
                  ></router-link>
                  <strong class="bd">
                    <em :class="!basic.mail ? 'error' : 'success'" ></em>
                  </strong>
                </p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="el-row">
          <el-col :span="24" :md="12">
            <el-row class="el-row">
              <el-col :span="24" :md="4" class="lable">
                <span>电话</span>
              </el-col>
              <el-col :span="24" :md="20">
                <p >
                  <span @click="$router.push({name:'set'})">
                    {{
                  basic.phone != ""
                  ? "+86 " +
                  basic.phone.substr(0, 3) +
                  "****" +
                  basic.phone.substr(7, 11)
                  : "+86 "
                  }}
                  </span>
                  <router-link
                    style="color: #888; margin-left: 10px"
                    class="fa-pencil fa"
                    to="/user/set"
                  ></router-link>
                  <strong class="bd">
                    <em :class="!basic.phone ? 'error' : 'success'" ></em>
                  </strong>
                </p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- <div class="inner">
          <div class="col-name">账号昵称</div>
          <div class="col-content">
            <span>{{ basic.nickName }}</span>
          </div>
        </div>
        <div class="inner layout-flex">
          <div class="col-name">联系手机</div>
          <div class="col-content">
            <span>
              {{
              basic.phone != ""
              ? "+86 " +
              basic.phone.substr(0, 3) +
              "****" +
              basic.phone.substr(7, 11)
              : "+86 "
              }}
            </span>
            <router-link style="color: #888; margin-left: 10px" class="fa-pencil fa" to="/user/set"></router-link>
            <strong>
              <em style="margin-left: 10px" :class="!basic.phone ? 'error' : 'success'"></em>
            </strong>
          </div>
        </div>
        <div class="inner layout-flex">
          <div class="col-name">联系邮箱</div>
          <div class="col-content">
            <span>{{ basic.emailFormat }}</span>
            <router-link style="color: #888; margin-left: 10px" class="fa-pencil fa" to="/user/set"></router-link>
          </div>
        </div>
        <div class="inner layout-flex">
          <div class="col-name">实名认证</div>
          <div class="col-content">
            <strong>
              <em :class="!basic.realType ? 'error' : 'success'"></em>
              <router-link
                style="
                    text-decoration: none;
                    margin-left: 10px;
                    color: #409eff;
                  "
                to="/user/real-auth"
              >查看或修改认证</router-link>
            </strong>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import TopTitle from "@/components/common/top-title";
export default {
  data () {
    return {
      basic: {
        avatar: "",
        editor: "",
        name: "",
        id: "",
        phone: "",
        email: "",
        realType: "",
        emailFormat: "",
      },
    };
  },
  created () {
    this.basic = JSON.parse(localStorage.getItem("userInfo"));
  },

  components: {
    TopTitle,
  },

  computed: {},

  methods: {
    emailFormat () {
      if (this.basic.email != "") {
        const len = this.basic.email.indexOf("@") - 3;
        const arr = [];
        for (let i = 0; i < len; i++) arr.push("*");
        this.basic.emailFormat =
          this.basic.email.substr(0, 3) +
          arr.join("") +
          this.basic.email.substr(
            this.basic.email.indexOf("@"),
            this.basic.email.length
          );
      }
      return this.basic.emailFormat;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin tit($bottom: 15px) {
  margin-bottom: $bottom;
  font-size: 16px;
  color: #666;
}
@mixin layout-flex() {
  margin-bottom: 20px;
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}
.el-row {
  margin: 10px 0 0;
  &::v-deep .el-input__inner {
    border-radius: 2px;
  }
  &::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .avatar {
    width: 100px;
    border-radius: 4px;
  }
  .lable {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    color: #666;
  }
  p {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    color: #666;
    font-weight: normal;
    // border: 1px solid #eee;
    // border-radius: 2px;
  }
}
.content {
  width: 100%;
  .part1 {
    @include layout-flex();
    .col-md9 {
      margin-bottom: 20px;
    }
    .data {
      padding: 20px 20px 40px;
      background: #fff;
      .tit {
        @include tit(0);
        font-weight: normal;
        padding: 15px 0;
        border-bottom: 1px solid #ebeef5;
      }
      .inner {
        padding: 15px;
        .col-name {
          margin-right: 20px;
          color: #999;
          font-size: 14px;
          width: 7%;
        }
        .col-content {
          span {
            font-size: 14px;
            color: #666;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.bd{
  em {
    margin-left: 10px;
    position: relative;
  &.success {
    color: $success;
    &::before {
      content: '已绑定';
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: '未绑定';
    }
  }
}
}
em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: '已认证';
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: '未认证';
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
</style>